export default {
  Dashboards: 'Дашборд',
  CRM: 'CRM',
  Analytics: 'Аналитика',
  eCommerce: 'Коммерция',
  Calendar: 'Календарь',
  Chat: 'Чат',
  Email: 'Email',
  Invoice: 'Счет',
  List: 'Список',
  Preview: 'Превью',
  Edit: 'Редактировать',
  Add: 'Добавить',
  User: 'Пользователь',
  'User List': 'Список пользователей',
  'User View': 'User View',
  'APPS AND PAGES': 'ПРИЛОЖЕНИЯ И СТРАНИЦЫ',
  Pages: 'Страницы',
  Authentication: 'Аутентификация',
  'Login V1': 'Login V1',
  'Login V2': 'Login V2',
  'Register V1': 'Register V1',
  'Register V2': 'Register V2',
  'Forgot Password V1': 'Forgot Password V1',
  'Forgot Password V2': 'Forgot Password V2',
  'Reset Password V1': 'Reset Password V1',
  'Reset Password V2': 'Reset Password V2',
  Miscellaneous: 'Смешанный',
  'Under Maintenance': 'Технические работы',
  Error: 'Ошибка',
  'Coming Soon': 'Скоро вернемся',
  'Not Authorized': 'Вы не авторизованы',
  'Knowledge Base': 'База знаний',
  'Account Settings': 'Настройки аккаунта',
  Pricing: 'Цены',
  FAQ: 'FAQ',
  'USER INTERFACE': 'ИНТЕРФЕЙС ПОЛЬЗОВАТЕЛЯ',
  Typography: 'Типография',
  Icons: 'Иконки',
  Gamification: 'Игрофикация',
  Cards: 'Карточки',
  Basic: 'Базовый',
  Statistics: 'Статистика',
  Advance: 'Advance',
  Actions: 'Actions',
  Chart: 'Chart',
  Components: 'Компоненты',
  Alert: 'Alert',
  Avatar: 'Avatar',
  Badge: 'Badge',
  Button: 'Button',
  Menu: 'Menu',
  'Expansion Panel': 'Expansion Panel',
  Dialog: 'Dialog',
  Pagination: 'Pagination',
  Tabs: 'Tabs',
  Tooltip: 'Tooltip',
  Chip: 'Chip',
  Snackbar: 'Snackbar',
  Stepper: 'Stepper',
  Timeline: 'Timeline',
  Treeview: 'Treeview',
  'FORMS & TABLES': 'ФОРМЫ И ТАБЛИЦЫ',
  'Forms Elements': 'Элементы форм',
  Autocomplete: 'Autocomplete',
  Checkbox: 'Checkbox',
  Combobox: 'Combobox',
  'File Input': 'File Input',
  Radio: 'Radio',
  'Range Slider': 'Range Slider',
  Select: 'Select',
  Slider: 'Slider',
  Switch: 'Switch',
  Textarea: 'Textarea',
  Textfield: 'Textfield',
  'Date Picker': 'Date Picker',
  'Month Picker': 'Month Picker',
  'Time Picker': 'Time Picker',
  Rating: 'Rating',
  'Form Layout': 'Разметка форм',
  'Form validation': 'Валидация форм',
  Tables: 'Tables',
  'Simple Table': 'Simple Table',
  Datatable: 'Datatable',
  'CHARTS AND MAPS': 'ГРАФИКИ И КАРТЫ',
  Charts: 'Charts',
  'Apex Chart': 'Apex Chart',
  Chartjs: 'Chartjs',
  'Leaflet Maps': 'Leaflet Maps',
  Others: 'Другое',
  'Menu Levels': 'Menu Levels',
  'Menu Level 2.1': 'Menu Level 2.1',
  'Menu Level 2.2': 'Menu Level 2.2',
  'Menu Level 3.1': 'Menu Level 3.1',
  'Menu Level 3.2': 'Menu Level 3.2',
  'Disabled Menu': 'Disabled Menu',
  'Raise Support': 'Raise Support',
  Documentation: 'Documentation',
  'Charts & Maps': 'Charts & Maps',
  Apps: 'Apps',
  'User Interface': 'User Interface',
  'Forms & Tables': 'Forms & Tables',
  Welcome: 'добро пожаловать',
  Logout: 'Выйти',
  PPControl: 'Управление ПП',
  RateReport: 'Темпы РК',
  VTRReport: 'Досмотры(VTR)',
  Advertisers: 'Рекламодатели',
  RateReportElements: {
    DateToToolTip:
      '"Дата по" влияет на статистические данные по кампаниям и исключает завершенные кампании по дате остановки кампании.',
    Advertiser: 'Рекламодатель',
    CampaignId: 'РК',
    CampaignName: 'Название кампании',
    UnitType: 'Ед.закупки',
    Fact: 'Факт',
    Plan: 'План',
    Prognosis: 'Прогноз недокрут/перекрут',
    UnitsPerDayNeed: 'Нужно ед.в день',
    DaysLeft: 'Дней до завершения',
    CampaignDateStop: 'Дата остановки',
  },
  VtrReportElements: {
    Advertiser: 'Рекламодатель',
    CampaignId: 'РК',
    CampaignName: 'Название кампании',
    CreativeName: 'Креатив',
    Views: 'Показы',
    Clicks: 'Переходы',
    ViewTo25: 'Досмотры до 25',
    ViewTo50: 'Досмотры до 50',
    ViewTo75: 'Досмотры до 75',
    ViewTo100: 'Досмотры до 100',
    Download: 'Скачать',
  },
  TrackingCodeElements: {
    Id: 'ID',
    Domain: 'Домен',
    Advertiser: 'Рекламодтаель',
    Status: 'Статус',
    InActive: 'Не активный',
    Active: 'Активный',
    Action: 'Действия',
    BtnAdd: 'Создать',
    BtnView: 'Просмотреть',
    BtnEdit: 'Редактировать',
    BtnDelete: 'Удалить',
    HeaderTextAdd: 'Добавить',
    HeaderTextView: 'Просмотр',
    HeaderTextEdit: 'Редактировать',
    HeaderTextDelete: 'Удаление',
    BtnCopyClipboard: 'Скопировать код',
    BtnInstruction: 'Инструкция',
    TooltipCopyClipboard: 'Скопировано',
    DomainLabel: 'Домен',
    Pixel: 'Код ПП',
    TextDeleteConfirm: 'Вы действительно хотите удалить?',
  },
  GeoElements: {
    Clicks: 'Переходы',
    City: 'Город',
    HeaderTotalPeriod: 'Переходов за период',
    HeaderTotalAll: 'Переходов всего',
    clicksFrom1To300: 'от 1 до 300',
    clicksFrom300To500: 'от 300 до 500',
    clicksFrom500To1000: 'от 500 до 1000',
    clicksFromGreater1000: 'больше 1000',
  },
  AudienceElements: {
    CategoryName: 'Категория',
    Percent: 'Процент',
    HeaderFemale: 'Распределение женской аудитории по возрасту:',
    HeaderMale: 'Распределение мужской аудитории по возрасту:',
    Years: ' лет',
  },
  ProviderElements: {
    Provider: 'Поставщик',
    Views: 'Показы',
    Clicks: 'Переходы',
    Visits: 'Сеансы',
    Cost: 'Стоимость',
    Bounce: 'Отказы',
    Depth: 'Глубина',
    Duration: 'Время сек',
    SelectProvider: 'Поставщик',
  },
  DateTimePicker: {
    DateFrom: 'Дата с',
    DateTo: 'Дата по',
    Today: 'Сегодня',
    Yesterday: 'Вчера',
    Week: 'Неделя',
    Month: 'Месяц',
    AllPeriod: 'Весь период',
  },
  BtnSearch: 'Поиск',
  LblSearch: 'Поиск',
  CampaignsList: 'Список кампаний',
  CampaignStatistics: 'Основные данные',
  CampaignGeo: 'География',
  CampaignAuditory: 'Аудитории',
  CampaingCreatives: 'Креативы',
  CampaingCreativesDaily: 'Отчет по дням',
  CampaingCreativesVTR: 'VTR',
  CampaingProvidersReport: 'Поставщики',
  Total: 'Общее',
  Geo: 'География',
  Socdem: 'Соцдем',
  Interests: 'Интересы',
  System: 'Система',
  Shows: 'Показы',
  References: 'Переходы',
  City: 'Город',
  Country: 'Страна',
  CTR: 'CTR',
  ShowUnactive: 'Показать неактывные',
  Age: 'Возраст',
  Gender: 'Пол',
  Female: 'Женский',
  Male: 'Мужской',
  Top10CategoriesHeader: 'TOP-10 активных категорий интересов',
  Browser: 'Браузер',
  OS: 'ОС',
  Options: 'Опции',
  Advertiser: 'Рекламодатель',
  Сoverage: 'Охват',
  CampaignsCount: 'Количество кампаний',
  ActiveCampigns: 'Активные',
  PausedCampigns: 'Приостановленные',
  InactiveCampigns: 'Завершенные',
  BtnSave: 'Сохранить',
  BtnCancel: 'Отмена',
  BtnClose: 'Закрыть',
  BtnYes: 'Да',
  BtnNo: 'Нет',
  Required: 'Поле не должно быть пустым',
  UrlRequired: 'Не корректный URL',
  Name: 'Название',
  Viewability: 'Viewability',
  Fraud: 'Fraud',
  Start: 'Начало',
  End: 'Окончание',
  Status: 'Статус',
  Campaign: {
    Status: {
      Paused: 'Приостановлена',
      Active: 'Активна',
      Inactive: 'Завершена',
      OnModaretion: 'На модерации',
    },
    Types: {
      Video: 'Видео',
      Banners: 'Баннеры',
      Native: 'Нативный',
    },
    Locations: {
      Desktop: 'Дестктоп',
      Mobile: 'Мобайл',
    },
  },
  Activity: 'Активность',
  IsWeekly: 'По неделям',
  BrandSafty: 'Brand Safty',
  SaftyLevel2: 'Уровень 2 - ненормативная лексика + слова, связанные с насилием',
  SaftyLevel3: 'Уровень 3 - ненормативная лексика + слова, связанные с насилием + слова по тематике adult',
  SaftyLevel4:
    'Уровень 4 - ненормативная лексика + слова, связанные с насилием + слова по тематике adult + политико-религиозный контент',
  SaftyLevel5:
    'Уровень 5 - ненормативная лексика + слова, связанные с насилием + слова по тематике adult + политико-религиозный контент + персональные стоп-слова от клиента',
  RestrictedWords: 'Запрещенная морфология',
  BrandSaftyDescription:
    'Ниже представлен список стоп слов и регулярных выражений, на основе котороного осуществляется Brand Safety',
  Level2: 'Уровень 2',
  Level3: 'Уровень 3',
  Level4: 'Уровень 4',
  Level5: 'Уровень 5',
  Words: 'Слова',
  AllPeriod: 'За весь период',
  SelectedPeriod: 'За выбранный период',
  ShowsDecsription: 'Количество показов рекламного объявления (баннера).',
  ReferencesDescription: 'Количество переходов по баннеру зафиксированных системой.',
  CTRDescription: 'CTR определяется как отношение числа кликов на баннер к числу показов, измеряется в процентах.',
  PIDescription: 'Соотношение сеансов к кликам.',
  PI: 'PI',
  CoverageDescription: 'Количество уникальных посетителей охваченных рекламной кампании.',
  RFDescription: 'Частота рекламного контакта с уникальным пользователем.',
  RF: 'RF',
  ViewabilityDescription:
    'Метрика, позволяющая отследить фактический показ баннера и время его нахождения в зоне видимости',
  FraudDescription: 'Почему-то нет описания.',
  Interactions: 'Взаимодейст.',
  InteractionsDescription: 'Почему-то нет описания.',
  Views: 'Досмотры',
  ViewsDescription: 'Почему-то нет описания.',
  Coast: 'Стоимость',
  CoastDescription: 'Стоимость.',
  Date: 'Дата',
  DateFrom: 'Дата с',
  DateTo: 'Дата по',
  StatisyicsForCampaign: 'Статистика РК',
  CPC: 'CPC',
  CPM: 'CPM',
  ReportForPeriod: 'Отчет за период',
  ReportTotal: 'Всего',
  Reports: 'Отчеты',
  Creative: 'Креатив',
  SummarySheet: 'Сводный лист',
  SummaryACData: 'Сводные данные РК',
  SummaryACData2: 'Суммарные данные РК',
  UniqUsers: 'Уники',
  Summary: 'Итого',
  VTR: {
    SoundOff: 'Звук выключен',
    SoundOn: 'Звук включен',
    PausePressed: 'Нажата "пауза"',
    ClousedByUser: 'Закрыто пользователем',
    SkipAdv: 'Клик по "пропустить рекламу"',
  },
  EventGroups: 'Группы событий',
  ViewDurationGroups: 'Группы длительности просмотра',
  PrecentUnder10: 'До 10%',
  Precent11_25: '11-25%',
  Precent26_50: '26-50%',
  Precent51_75: '51-75%',
  Precent76_99: '76-99%',
  Precent100: '100%',
  PrecentUpTo25: 'Досмотрели до 25%',
  PrecentUpTo50: 'Досмотрели до 50%',
  PrecentUpTo75: 'Досмотрели до 75%',
  PrecentUpTo100: 'Досмотрели до 100%',
  OpenVTR: 'Открыть VTR',
  VTRRequest: 'Запрос открытия VTR',
  OpenVTRDescription: 'В данный момент для этой кампании VTR не доступен.',
  VTRReauestSended: 'Спасибо, ваш запрос отправлен!',
  DaysDetails: 'Детализация дни',
  Watchups: 'Досмотры',
  Events: 'События',
  CampaignStatisticsExcel: 'Статистика по кампании',
  CreativeStatisticsExcel: 'Статистика по креативу',
  ViewShare: 'Доля просмотра',
  Event: 'Событие',
  AdvertisersGenerateReport: 'Генерация отчетов',
  GenerateReport: 'Сформировать отчет',
  ReportGeneration: {
    Warning: 'Внимание!',
    WarningText: 'Вы действительно хотите удалить этот отчет?',
    DetailedInfo: 'Детальная информация',
    Name: 'Наименование',
    Parameters: 'Параметры',
    Campaigns: 'Кампании',
    CreatedDate: 'Дата создания',
    UpdatedDate: 'Дата изменения',
    Status: 'Статуст',
    User: 'Пользователь',
    SelectAll: 'Выбрать все',
    Actions: 'Действия',
    Save: 'Сохранить',
    Close: 'Закрыть',
    Statuses: {
      NEW: 'Новый',
      IN_PROGRESS: 'В обработке',
      COMPLETED: 'Завершён',
      FAILED: 'Отклонён',
    },
    Details: 'Детали',
    Delete: 'Удалить',
    Download: 'Скачать',
    CreateReport: 'Создать отчет',
    CreateQuery: 'Создать запрос',
    Summary: 'Сводная',
    Tools: 'Инструменты',
    ToolsReport: 'Отчет по инструментам',
    Banners: 'Баннеры',
    BannersReport: 'Отчет по креативам',
    Weeks: 'Недели',
    WeeksReport: 'Отчет по дням недели',
    DaysDynamics: 'Динамика, дни',
    DaysDynamicsReport: 'Динамика кампании по дням',
    RunnigUpSummary: 'Нарастающим итогом',
    RunnigUpSummaryReport: 'Динамика кампании нарастающим итогом',
    Geo: 'География',
    GeoReport: 'ТОП-25 Городов (География)',
    Auditory: 'Аудитория',
    AuditoryReport: 'Аудитория рекламной кампании',
    ACReport: 'Oтчёт по рекламной кампании',
    Advertiser: 'Рекламодатель',
    Brand: 'Бренд',
    CampaignPeriod: 'Период рекламной кампании',
    ReportPeriod: 'Отчётный период',
    From: 'c',
    To: 'по',
    SummaryBannersShows: 'Общее количество показов баннеров:',
    SummaryClicks: 'Общее количество нажатий:',
    Coverage: 'Баннеры увидело всего, пользователей (охват):',
    RF: 'Среднее кол-во показов на пользователя (частота):',
    CTR: 'Средний процент отклика (CTR, %):',
    UniqUsers: 'Уникальных посетителей, пришедших с баннеров:',
    BannersCount: 'Количество баннеров в ротации:',
    CampaingTools: 'Инструменты кампании:',
    PlacementType: 'Тип размещения',
    Location: 'Локация',
    Tool: 'Инструмент',
    Shows: 'Показы',
    Clicks: 'Нажатия',
    CtrTable: 'CTR, %',
    CoverageTable: 'Охват',
    Frequency: 'Частота',
    Баннеры: 'Баннеры',
    Видео: 'Видео',
    Десктоп: 'Десктоп',
    Мобайл: 'Мобайл',
    'Десктоп + Мобайл': 'Десктоп и Мобайл',
    'Аудиторное размещение': 'Аудиторное размещение',
    'look-a-like': 'Look-a-like',
    Native: 'Native',
    'Кастомный сегмент': 'Кастомный сегмент',
    Ремаркетинг: 'Ремаркетинг',
    AdvertisingCampaign: 'Рекламная кампания',
    Banner: 'Баннер',
    Понедельник: 'Понедельник',
    Вторник: 'Вторник',
    Среда: 'Среда',
    Четверг: 'Четверг',
    Пятница: 'Пятница',
    Суббота: 'Суббота',
    Воскресенье: 'Воскресенье',
    DayOfTheWeek: 'День недели',
    Date: 'Дата',
    Viewability: 'Viewability',
    Fraud: 'Fraud',
    SummaryDays: 'Итого',
    City: 'Город',
    AuditoryCharacteristics: 'Характеристика аудитории',
    AuditoryInterests: 'Аудиторные интересы',
    AuditoryLaL: 'L-a-L (поиск подобных)',
    AuditoryRemarketing: 'Ремаркетинг',
    M: 'Мажчины',
    W: 'Женщины',
    Gender: 'Пол',
    Age: 'Возраст',
    Interests: 'Интересы',
    Loading: 'Обрабатываем данные',
  },
  Login: 'Логин',
  Password: 'Пароль',
  LoginError: 'Не получилось войти! Неверный логин или пароль.',
  SignIn: 'Войти',
  Users: 'Пользователи',
  LinkUser: 'Привязка',
  ReportGenerationMenu: 'Генерация отчетов',
  Notifications: 'Уведомления',
  NewNotifications: 'новых',
  RemoveAllNotifications: 'Убрать все',
  ReadAllNotifications: 'Прочитать все',
  NoNotificationsYet: 'Пока ничего нет',
  Profile: 'Профиль',
  CampaingType: 'Тип кампании',
  Location: 'Локация',
  Provider: 'Поставщик',
  NoPlotDataAlert: 'Нет данных для постороние графика.',
  DisplayType: 'Вид отображения',
  CampaignFilter: 'Кампания',
  EmptyCampaignId: 'Кампания не выбрана. Выберите кампанию, для отображения.',
  CreativeVTRStatistics: 'Статистика VTR',
  UserPage: {
    SavedSuccesfully: 'Данные успешно сохранены',
    Create: 'Создать',
    UserInfo: 'Учетные данные пользователя',
    ChangePassword: 'Изменить пароль',
    UserCreation: 'Создание пользователя',
    UserEdit: 'Редактирование пользователя',
    Validation: {
      NameRequired: 'Имя пользователя обязательно для заполнения',
      EmailRequired: 'Email обязателен для заполнения',
      EmailIncorrect: 'Не корректный email',
      RoleRequired: 'Роль обязательная для заполнения',
      PasswordRequired: 'Пароль обязателен для заполнения',
      PasswordNotMatch: 'Пароли не совпадают',
      PasswordLength: 'Длинна пароля не должна быть менне 6 символов',
      EmailExists: 'Данный Email уже занят. Пожалуйста введите другой',
      PasswordChangesSuccess: 'Пароль успешно изменен',
      DomainRequired: 'Домен обязтелен для заполнения',
      CabinetRequired:
        'Для пользователя с ролью "Менеджер" требуется открыть доступ к одному из ЛК (можно выбрать толко один ЛК)',
    },
    UserName: 'Имя',
    Email: 'Email',
    UpdatePassword: 'Обновить пароль',
    Role: 'Роль',
    Password: 'Пароль',
    NewPassword: 'Новый пароль',
    ConfirmPassword: 'Подтвердить пароль',
    Save: 'Сохранить',
    Cancel: 'Отмена',
    Agency: 'Агентство',
    Status: 'Статус',
    UserRules: 'Доступы пользователя',
    TechDomain: 'Технический домен',
    EditRules: 'Редактировать правила доступа',
    Actions: 'Действия',
    Roles: {
      1: 'Root',
      5: 'Рекламодатели',
      2: 'Супер администраторы',
      3: 'Администраторы',
      4: 'Менеджеры администраторы',
      8: 'Поставщики',
      6: 'Менеджеры',
      7: 'Пользователи рекламодателя',
      ROOT: 'Root',
      ADVERTISERS: 'Рекламодатели',
      SUPER_ADMINS: 'Супер администраторы',
      ADMINS: 'Администраторы',
      MANAGER_ADMINS: 'Менеджеры администраторы',
      PROVIDERS: 'Поставщики',
      MANAGERS: 'Менеджеры',
      ADVERTISERS_USERS: 'Пользователи рекламодателя',
    },
    Edit: 'Редактировать',
    LinkUsers: 'Привязать пользователей',
    Delete: 'Удалить',
    Statuses: {
      Active: 'Активный',
      Inactive: 'Не активный',
    },
    UserDeleted: 'Пользователь успешно удален',
    Warning: 'Внимание!',
    WarningText: 'Вы действительно хотите удалить данного пользователя?',
    WarningCantDeleteText: 'Невозможно удалить пользователя, так как он имеет хотя бы одну кампанию!',
    Yes: 'ДА',
    No: 'НЕТ',
    LinkToUser: 'Привязать к пользователю',
  },
  UsersLink: 'Привязка пользователей',
  ProfilePage: {
    Profile: 'Профиль',
    ChangePassword: 'Изменить пароль',
    UserName: 'Имя',
    Email: 'Эл. Адрес',
    OldPassword: 'Текущий пароль',
    NewPassword: 'Новый пароль',
    ConfirmPassword: 'Подтвердить пароль',
    Role: 'Роль',
    Validation: {
      IncoreectOldPassword: 'Текущий пароль введен не правильно',
      UserUpdatedSuccess: 'Данные успешно обновлены',
    },
    Save: 'Сохранить изменения',
    Cancel: 'Отмена',
    UploadAvatar: 'Загрузить новое фото',
    AvatarRequerments: 'Допускаются JPG, JPEG или PNG',
    Roles: {
      1: 'Root',
      5: 'Рекламодатели',
      2: 'Супер администраторы',
      3: 'Администраторы',
      4: 'Менеджеры администраторы',
      8: 'Поставщики',
      6: 'Менеджеры',
      7: 'Пользователи рекламодателя',
      ROOT: 'Root',
      ADVERTISERS: 'Рекламодатели',
      SUPER_ADMINS: 'Супер администраторы',
      ADMINS: 'Администраторы',
      MANAGER_ADMINS: 'Менеджеры администраторы',
      PROVIDERS: 'Поставщики',
      MANAGERS: 'Менеджеры',
      ADVERTISERS_USERS: 'Пользователи рекламодателя',
    },
  },
  StatExcelHeader: 'Статистика по рекламным кампаниям',
  CampaignListExcelHeader: 'Рекламные кампании',
  VTRExcelHeader: 'Отчет по VTR',
  ProvidersExcelHeader: 'Отчет по поставщикам',
  Показы: 'Показы',
  Переходы: 'Переходы',
}
