export default {
  Dashboards: 'Dashboards',
  CRM: 'CRM',
  Analytics: 'Analytics',
  eCommerce: 'eCommerce',
  Calendar: 'Calendar',
  Chat: 'Chat',
  Email: 'Email',
  Invoice: 'Invoice',
  List: 'List',
  Preview: 'Preview',
  Edit: 'Edit',
  Add: 'Add',
  User: 'User',
  'User List': 'User List',
  'User View': 'User View',
  'APPS AND PAGES': 'APPS AND PAGES',
  Pages: 'Pages',
  Authentication: 'Authentication',
  'Login V1': 'Login V1',
  'Login V2': 'Login V2',
  'Register V1': 'Register V1',
  'Register V2': 'Register V2',
  'Forgot Password V1': 'Forgot Password V1',
  'Forgot Password V2': 'Forgot Password V2',
  'Reset Password V1': 'Reset Password V1',
  'Reset Password V2': 'Reset Password V2',
  Miscellaneous: 'Miscellaneous',
  'Under Maintenance': 'Under Maintenance',
  Error: 'Error',
  'Coming Soon': 'Coming Soon',
  'Not Authorized': 'Not Authorized',
  'Knowledge Base': 'Knowledge Base',
  'Account Settings': 'Account Settings',
  Pricing: 'Pricing',
  FAQ: 'FAQ',
  'USER INTERFACE': 'USER INTERFACE',
  Typography: 'Typography',
  Icons: 'Icons',
  Gamification: 'Gamification',
  Cards: 'Cards',
  Basic: 'Basic',
  Statistics: 'Statistics',
  Advance: 'Advance',
  Actions: 'Actions',
  Chart: 'Chart',
  Components: 'Components',
  Alert: 'Alert',
  Avatar: 'Avatar',
  Badge: 'Badge',
  Button: 'Button',
  Menu: 'Menu',
  'Expansion Panel': 'Expansion Panel',
  Dialog: 'Dialog',
  Pagination: 'Pagination',
  Tabs: 'Tabs',
  Tooltip: 'Tooltip',
  Chip: 'Chip',
  Snackbar: 'Snackbar',
  Stepper: 'Stepper',
  Timeline: 'Timeline',
  Treeview: 'Treeview',
  'FORMS & TABLES': 'FORMS & TABLES',
  'Forms Elements': 'Forms Elements',
  Autocomplete: 'Autocomplete',
  Checkbox: 'Checkbox',
  Combobox: 'Combobox',
  'File Input': 'File Input',
  Radio: 'Radio',
  'Range Slider': 'Range Slider',
  Select: 'Select',
  Slider: 'Slider',
  Switch: 'Switch',
  Textarea: 'Textarea',
  Textfield: 'Textfield',
  'Date Picker': 'Date Picker',
  'Month Picker': 'Month Picker',
  'Time Picker': 'Time Picker',
  Rating: 'Rating',
  'Form Layout': 'Form Layout',
  'Form validation': 'Form validation',
  Tables: 'Tables',
  'Simple Table': 'Simple Table',
  Datatable: 'Datatable',
  'CHARTS AND MAPS': 'CHARTS AND MAPS',
  Charts: 'Charts',
  'Apex Chart': 'Apex Chart',
  Chartjs: 'Chartjs',
  'Leaflet Maps': 'Leaflet Maps',
  Others: 'Others',
  'Menu Levels': 'Menu Levels',
  'Menu Level 2.1': 'Menu Level 2.1',
  'Menu Level 2.2': 'Menu Level 2.2',
  'Menu Level 3.1': 'Menu Level 3.1',
  'Menu Level 3.2': 'Menu Level 3.2',
  'Disabled Menu': 'Disabled Menu',
  'Raise Support': 'Raise Support',
  Documentation: 'Documentation',
  'Charts & Maps': 'Charts & Maps',
  Apps: 'Apps',
  'User Interface': 'User Interface',
  'Forms & Tables': 'Forms & Tables',
  Welcome: 'welcome',
  Logout: 'Logout',
  PPControl: 'PP сontrol',
  RateReport: 'AC rates',
  VTRReport: 'VTR',
  Advertisers: 'Advertisers',
  RateReportElements: {
    DateToToolTip:
      '"Date To" affects campaign statistics and excludes completed campaigns based on the campaign stop date.',
    Advertiser: 'Advertiser',
    CampaignId: 'Campaign Id',
    CampaignName: 'Campaign name',
    UnitType: 'Unit type',
    Fact: 'Fact',
    Plan: 'Plan',
    Prognosis: 'Prognosis',
    UnitsPerDayNeed: 'Units per day need',
    DaysLeft: 'Days left',
    CampaignDateStop: 'Stop date',
  },
  VtrReportElements: {
    Advertiser: 'Advertiser',
    CampaignId: 'Campaign Id',
    CampaignName: 'Campaign name',
    CreativeName: 'Creative name',
    Views: 'Video complete',
    Clicks: 'Clicks',
    ViewTo25: 'ViewTo25',
    ViewTo50: 'ViewTo50',
    ViewTo75: 'ViewTo75',
    ViewTo100: 'ViewTo100',
    Download: 'Download',
  },
  GeoElements: {
    Clicks: 'Clicks',
    City: 'City',
    HeaderTotalPeriod: 'Clicks period',
    HeaderTotalAll: 'Clicks total',
    clicksFrom1To300: 'from 1 to 300',
    clicksFrom300To500: 'from 300 to 500',
    clicksFrom500To1000: 'from 500 to 1000',
    clicksFromGreater1000: 'greater than 1000',
  },
  AudienceElements: {
    CategoryName: 'Category',
    Percent: 'Percent',
    HeaderFemale: 'Distribution of female audience by age:',
    HeaderMale: 'Distribution of male audience by age:',
    Years: ' years',
  },
  ProviderElements: {
    Provider: 'Provider',
    Views: 'Video complete',
    Clicks: 'Clicks',
    Visits: 'Visits',
    Cost: 'Cost',
    Bounce: 'Bounce',
    Depth: 'Depth',
    Duration: 'Duration',
    SelectProvider: 'Provider',
  },
  TrackingCodeElements: {
    Id: 'ID',
    Domain: 'Domain',
    Advertiser: 'Advertiser',
    Status: 'Status',
    InActive: 'In active',
    Active: 'Active',
    Action: 'Actions',
    BtnAdd: 'Create',
    BtnView: 'View',
    BtnEdit: 'Edit',
    BtnDelete: 'Delete',
    HeaderTextAdd: 'Add',
    HeaderTextView: 'View',
    HeaderTextEdit: 'Edit',
    HeaderTextDelete: 'Delete',
    BtnCopyClipboard: 'Copy to clipboard',
    BtnInstruction: 'Instruction',
    TooltipCopyClipboard: 'Copied',
    DomainLabel: 'Domain',
    Pixel: 'Pixel',
    TextDeleteConfirm: 'Do you really want to delete?',
  },
  DateTimePicker: {
    DateFrom: 'Date from',
    DateTo: 'Date to',
    Today: 'Today',
    Yesterday: 'Yesterday',
    Week: 'Week',
    Month: 'Month',
    AllPeriod: 'All period',
  },
  BtnSearch: 'Search',
  LblSearch: 'Search',
  CampaignsList: 'Campaings list',
  CampaignStatistics: 'Basic data',
  CampaignGeo: 'Geography',
  CampaignAuditory: 'Audience',
  CampaingCreatives: 'Creatives',
  CampaingCreativesDaily: 'Daily report',
  CampaingCreativesVTR: 'VTR',
  CampaingProvidersReport: 'Providers',
  Total: 'Total',
  Geo: 'Geography',
  Socdem: 'Socdem',
  Interests: 'Interests',
  System: 'System',
  Shows: 'Impressions',
  References: 'Clicks',
  City: 'City',
  Country: 'Country',
  CTR: 'CTR',
  ShowUnactive: 'Show unactive',
  Age: 'Age',
  Gender: 'Gender',
  Female: 'Female',
  Male: 'Male',
  Top10CategoriesHeader: 'TOP-10 active interest cagegories',
  Browser: 'Browser',
  OS: 'OS',
  Options: 'Options',
  Advertiser: 'Advertiser',
  Сoverage: 'Uniq impressions',
  CampaignsCount: 'Campaigns count',
  ActiveCampigns: 'Active',
  PausedCampigns: 'Paused',
  InactiveCampigns: 'Inactive',
  BtnSave: 'Save',
  BtnCancel: 'Cancel',
  BtnClose: 'Close',
  BtnYes: 'Yes',
  BtnNo: 'No',
  Required: 'This field is required',
  UrlRequired: 'URL is invalid',
  Name: 'Name',
  Viewability: 'Viewability',
  Fraud: 'Fraud',
  Start: 'Start',
  End: 'End',
  Status: 'Status',
  Campaign: {
    Status: {
      Paused: 'Paused',
      Active: 'Active',
      Inactive: 'Inactive',
      OnModaretion: 'Modareting',
    },
    Types: {
      Video: 'Video',
      Banners: 'Banners',
      Native: 'Native',
    },
    Locations: {
      Desktop: 'Desktop',
      Mobile: 'Mobile',
    },
  },
  Activity: 'Activity',
  IsWeekly: 'By weeks',
  BrandSafty: 'Brand Safty',
  SaftyLevel2: 'Level 2 - profanity + words related to violence',
  SaftyLevel3: 'Level 3 - profanity + words related to violence + words on the subject of adult',
  SaftyLevel4:
    'Level 4 - profanity + words related to violence + words on the subject of adult + political and religious content',
  SaftyLevel5:
    'Level 5 - profanity + words related to violence + words on the subject of adult + political and religious content + personal stop words from the client',
  RestrictedWords: 'Forbidden morphology',
  BrandSaftyDescription:
    'Below is a list of stop words and regular expressions, on the basis of which Brand Safety is implemented',
  Level2: 'Level 2',
  Level3: 'Level 3',
  Level4: 'Level 4',
  Level5: 'Level 5',
  Words: 'Words',
  AllPeriod: 'All period',
  SelectedPeriod: 'Selected period',
  ShowsDecsription: 'The number of shows for an advertisement (banner)',
  ReferencesDescription: 'The number of clicks on the banner recorded by the system.',
  CTRDescription:
    'CTR is defined as the ratio of the number of clicks per banner to the number of impressions, measured in percent.',
  PIDescription: 'The ratio of sessions to clicks.',
  PI: 'PI',
  CoverageDescription: 'The number of unique visitors reached by the advertising campaign.',
  RFDescription: 'Frequency of advertising contact with a unique user.',
  RF: 'RF',
  ViewabilityDescription:
    'A metric that allows you to track the actual display of the banner and the time it is in view',
  FraudDescription: 'For some reason there is no description.',
  Interactions: 'Interactions',
  InteractionsDescription: 'For some reason there is no description.',
  Views: 'Video complete',
  ViewsDescription: 'For some reason there is no description.',
  Coast: 'Cost',
  CoastDescription: 'Cost.',
  Date: 'Date',
  DateFrom: 'Date from',
  DateTo: 'Date to',
  StatisyicsForCampaign: 'Statistics for',
  CPC: 'CPC',
  CPM: 'CPM',
  ReportForPeriod: 'Report for period',
  ReportTotal: 'Total',
  Reports: 'Reports',
  Creative: 'Creative',
  SummarySheet: 'Summary sheet',
  SummaryACData: 'Summary AC Data',
  SummaryACData2: 'Summary AC Data',
  UniqUsers: 'Uniques',
  Summary: 'Summary',
  VTR: {
    SoundOff: 'Sound off',
    SoundOn: 'Sound on',
    PausePressed: '"Pause" pressed',
    ClousedByUser: 'Closed by user',
    SkipAdv: '"Skip ad" pressed',
  },
  EventGroups: 'Event groups',
  ViewDurationGroups: 'View duration groups',
  PrecentUnder10: 'Under 10%',
  Precent11_25: '11-25%',
  Precent26_50: '26-50%',
  Precent51_75: '51-75%',
  Precent76_99: '76-99%',
  Precent100: '100%',
  PrecentUpTo25: 'Watched up to 25%',
  PrecentUpTo50: 'Watched up to 50%',
  PrecentUpTo75: 'Watched up to 75%',
  PrecentUpTo100: 'Watched up to 100%',
  OpenVTR: 'Open VTR',
  VTRRequest: 'VTR open request',
  OpenVTRDescription: 'VTR for this campaign is not avaible right now.',
  VTRReauestSended: 'Thanks, your request sent!',
  DaysDetails: 'Details by days',
  Watchups: 'Watchups',
  Events: 'Events',
  CampaignStatisticsExcel: 'Statistics for campaign',
  CreativeStatisticsExcel: 'Statistics for creative',
  ViewShare: 'View share',
  Event: 'Event',
  AdvertisersGenerateReport: 'Report generation',
  GenerateReport: 'Generate report',
  ReportGeneration: {
    Warning: 'Warning!',
    WarningText: 'Do You realy want to delete this report?',
    DetailedInfo: 'Detailed info',
    Name: 'Name',
    Parameters: 'Parameters',
    Campaigns: 'Campaigns',
    CreatedDate: 'Created at',
    UpdatedDate: 'Updated at',
    Status: 'Status',
    User: 'User',
    SelectAll: 'Select all',
    Actions: 'Actions',
    Save: 'Save',
    Close: 'Close',
    Statuses: {
      NEW: 'New',
      IN_PROGRESS: 'In progress',
      COMPLETED: 'Completed',
      FAILED: 'Failed',
    },
    Details: 'Details',
    CreateReport: 'Create report',
    Delete: 'Delete',
    Download: 'Download',
    CreateQuery: 'Create query',
    Summary: 'Summary',
    Tools: 'Tools',
    ToolsReport: 'Tools report',
    Banners: 'Banners',
    BannersReport: 'Creative report',
    Weeks: 'Weeks',
    WeeksReport: 'Week by days report',
    DaysDynamics: 'Days dynamic',
    DaysDynamicsReport: 'Campaign dynamic by days',
    RunnigUpSummary: 'Runnig up summary',
    RunnigUpSummaryReport: 'Campaign cumulative dynamics',
    Geo: 'Geography',
    GeoReport: 'TOP-25 cities (Geography)',
    Auditory: 'Auditory',
    AuditoryReport: 'Advetising campaign auditory',
    ACReport: 'Advertising campaign report',
    Advertiser: 'Advertiser',
    Brand: 'Brand',
    CampaignPeriod: 'Campaign period',
    ReportPeriod: 'Report period',
    From: 'from',
    To: 'to',
    SummaryBannersShows: 'Total banners show count:',
    SummaryClicks: 'Total click count:',
    Coverage: 'Amount of users, has viewed banners (coverage):',
    RF: 'Average shows per user (frequency):',
    CTR: 'Average response rate (CTR, %):',
    UniqUsers: 'Unique visitors from banners:',
    BannersCount: 'Number of banners in rotation:',
    CampaingTools: 'Campaign tools:',
    PlacementType: 'Placement type',
    Location: 'Location',
    Tool: 'Tool',
    Shows: 'Impressions',
    Clicks: 'Clicks',
    CtrTable: 'CTR, %',
    CoverageTable: 'Uniq impressions',
    Frequency: 'Frequency',
    Баннеры: 'Banners',
    Видео: 'Video',
    Десктоп: 'Desktop',
    Мобайл: 'Mobile',
    'Десктоп + Мобайл': 'Decktop and Mobile',
    'Аудиторное размещение': 'Audience placement',
    'look-a-like': 'Look-a-like',
    Native: 'Native',
    'Кастомный сегмент': 'Custom segment',
    Ремаркетинг: 'Remarketing',
    AdvertisingCampaign: 'Advertising campaing',
    Banner: 'Banner',
    Понедельник: 'Monday',
    Вторник: 'Tuesday',
    Среда: 'Wednesday',
    Четверг: 'Thursday',
    Пятница: 'Friday',
    Суббота: 'Saturday',
    Воскресенье: 'Sunday',
    DayOfTheWeek: 'Day of the week',
    Date: 'Date',
    Viewability: 'Viewability',
    Fraud: 'Fraud',
    SummaryDays: 'Summary',
    City: 'City',
    AuditoryCharacteristics: 'Auditory characteristics',
    AuditoryInterests: 'Auditory interests',
    AuditoryLaL: 'L-a-L (search for similar)',
    AuditoryRemarketing: 'Remarketing',
    M: 'Male',
    W: 'Female',
    Gender: 'Gender',
    Age: 'Age',
    Interests: 'Interests',
    Loading: 'Data processing',
  },
  Login: 'Login',
  Password: 'Password',
  LoginError: 'Failed to login! Wrong login or password.',
  SignIn: 'Sign in',
  Users: 'Users',
  LinkUser: 'Link users',
  ReportGenerationMenu: 'Reports generation',
  Notifications: 'Notifications',
  NewNotifications: 'new',
  RemoveAllNotifications: 'Remove All Notifications',
  ReadAllNotifications: 'Read All Notifications',
  NoNotificationsYet: 'Nothing here yet',
  Profile: 'Profile',
  CampaingType: 'Campaing type',
  Location: 'Location',
  Provider: 'Provider',
  NoPlotDataAlert: 'No data for graph render.',
  DisplayType: 'Display type',
  CampaignFilter: 'Campaign',
  EmptyCampaignId: 'Campaing must be selected. Select a campaign to show results.',
  CreativeVTRStatistics: 'VTR statistics',
  UserPage: {
    Create: 'Create',
    SavedSuccesfully: 'Data successfuly saved',
    UserInfo: 'User account data',
    ChangePassword: 'Change password',
    UserCreation: 'Create user',
    UserEdit: 'Edit user',
    Validation: {
      NameRequired: 'Name is required',
      EmailRequired: 'Email is required',
      EmailIncorrect: 'Incorrect email',
      RoleRequired: 'Role is required',
      PasswordRequired: 'Password is required',
      PasswordNotMatch: 'Passwords do not match',
      PasswordLength: 'Password length must be at least 6 characters',
      EmailExists: 'Email already exists. Please, enter enother one',
      PasswordChangesSuccess: 'Password successfuly changed',
      DomainRequired: 'Domain is required',
      CabinetRequired:
        'For users with "Manager" role, cabinet access must be specified (Only one cabinet can be selected)',
    },
    UserName: 'Name',
    Email: 'Email',
    UpdatePassword: 'Update password',
    Role: 'Role',
    Save: 'Save',
    Password: 'Password',
    NewPassword: 'New password',
    ConfirmPassword: 'Confirm password',
    Cancel: 'Cancel',
    Agency: 'Agency',
    Status: 'Status',
    UserRules: 'User privilege',
    TechDomain: 'Technical domain',
    EditRules: 'Edit access rules',
    Actions: 'Actions',
    Roles: {
      1: 'Root',
      5: 'Advertisers',
      2: 'Super admins',
      3: 'Admins',
      4: 'Manager admins',
      8: 'Providers',
      6: 'Managers',
      7: 'Advertisers users',
      ROOT: 'Root',
      ADVERTISERS: 'Advertisers',
      SUPER_ADMINS: 'Super admins',
      ADMINS: 'Admins',
      MANAGER_ADMINS: 'Manager admins',
      PROVIDERS: 'Providers',
      MANAGERS: 'Managers',
      ADVERTISERS_USERS: 'Advertisers users',
    },
    Edit: 'Edit',
    LinkUsers: 'Link users',
    Delete: 'Delete',
    Statuses: {
      Active: 'Active',
      Inactive: 'Inactive',
    },
    UserDeleted: 'User successfuly deleted',
    Warning: 'Warning!',
    WarningText: 'Do You realy want to delete this user?',
    WarningCantDeleteText: 'Can not delete user because of user has at least one campaing!',
    Yes: 'YES',
    No: 'NO',
    LinkToUser: 'Link to user',
  },
  UsersLink: 'Link users',
  ProfilePage: {
    Profile: 'Profile',
    ChangePassword: 'Change password',
    UserName: 'Name',
    Email: 'Email',
    OldPassword: 'Current password',
    NewPassword: 'New password',
    ConfirmPassword: 'Confirm password',
    Role: 'Role',
    Validation: {
      IncoreectOldPassword: 'Current password is incorrect',
      UserUpdatedSuccess: 'Data successfuly updated',
    },
    Save: 'Save changes',
    Cancel: 'Cancel',
    UploadAvatar: 'Upload new photo',
    AvatarRequerments: 'JPG, JPEG and PNG allowed',
    Roles: {
      1: 'Root',
      5: 'Advertisers',
      2: 'Super admins',
      3: 'Admins',
      4: 'Manager admins',
      8: 'Providers',
      6: 'Managers',
      7: 'Advertisers users',
      ROOT: 'Root',
      ADVERTISERS: 'Advertisers',
      SUPER_ADMINS: 'Super admins',
      ADMINS: 'Admins',
      MANAGER_ADMINS: 'Manager admins',
      PROVIDERS: 'Providers',
      MANAGERS: 'Managers',
      ADVERTISERS_USERS: 'Advertisers users',
    },
  },
  StatExcelHeader: 'Advertising campaign statistics',
  CampaignListExcelHeader: 'Advertising companies',
  VTRExcelHeader: 'VTR report',
  ProvidersExcelHeader: 'Providers report',
  Показы: 'Shows',
  Переходы: 'Clicks',
}
