export default {
  install(Vue) {
    // define an instance method
    // https://service.ggads.io/dashboard/api
    // 192.168.62.112:7080 - тестовый
    // https://service.mobidriven.com/dashboard/api

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$apiBaseURL = 'https://service.ggads.io/dashboard/api'

    // eslint-disable-next-line no-param-reassign, func-names
    Vue.prototype.$getUserAdvertisers = function () {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData.advertisers === null) {
        return userData.id
      }

      return userData.advertisers
    }

    // eslint-disable-next-line no-param-reassign, func-names
    Vue.prototype.$getUserId = function () {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData) {
        return userData.id
      }

      return 0
    }

    // eslint-disable-next-line no-param-reassign, func-names
    Vue.prototype.$getUser = function () {
      const userData = JSON.parse(localStorage.getItem('userData'))
      if (userData) {
        return userData
      }

      return 0
    }
    // eslint-disable-next-line no-param-reassign, func-names
    Vue.prototype.$ucFirst = function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  },
}
